import React, { useState } from "react";
import { useContext } from "react";
import { GlobalContext } from "../globalContext";
import johnImage from "../assets/images/john-cornors.jpg";
import ChangePasswordModal from "../components/ChangePasswordModal";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
  getAllWebsites,
  logout,
  websiteList,
} from "../pages/dataService/DataService";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  IoIosArrowBack,
  IoIosArrowDropleftCircle,
  IoIosArrowForward,
} from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { IoArrowBack, IoShareSocial } from "react-icons/io5";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
const Header = ({ isSideBarActive, setIsSideBarActive }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { bwebsiteId, setNavigation } = useContext(GlobalContext);
  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  let getAllWebsite = useQuery("AllWebsites", getAllWebsites);

  console.log("value of the website are  : ", getAllWebsite?.data);

  // Usage example
  const website_name = getCookie("websiteName");
  const userRole = getCookie("userRole");

  const handlewebsiteredirect = () => {
    //setShowModal(true);

    Cookies.remove("websiteId");
    Cookies.remove("websiteName");

    navigate("/websites-list");
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLogout = async () => {
    const response = await logout();

    if (response.status === 200) {
      Cookies.remove("websiteId");
      Cookies.remove("websiteName");
      Cookies.remove("user");    
      Cookies.remove("userId"); 
      Cookies.remove("websiteCode");    

      // Cookies.remove("bwebsiteId");
      // if(bwebsiteId=="AHR")
      //   Cookies.remove("ahrtoken");
      //   else if (bwebsiteId=="TOC")
      //     Cookies.remove("toctoken");
      navigate("/");
    }
  };

  const handelSelect = (webisteData) => {
    // console.log("webisteData",webisteData)
    // console.log("value is change changes the cookies  ", webisteData);
    if (webisteData?.user_is_active > 0) {
      Cookies.set("websiteId", webisteData?.website_id);
      Cookies.set("propertyId", webisteData?.property_id);
      Cookies.set("websiteName", webisteData?.website); 
      Cookies.set("websiteCode", webisteData?.website_code);
      Cookies.set("userRole", webisteData?.user_role);
      const navigationUrl = webisteData?.navigation_url;
      if (!navigationUrl) {
        toast.dismiss();
        toast.error("No module find");
      }
      navigate(navigationUrl);
      setNavigation(webisteData?.navigation_item);
      queryClient.resetQueries();

      // setNavigation()
    } else {
      handleLogout();
    }
  };

  const user_name = getCookie("user");
  return (
    <>
      <header
        id="header"
        className={`page-header position-fixed bg-white end-0 top-0 ${
          isSideBarActive ? "close_icon" : ""
        } `}
      >
        {process.env.REACT_APP_ENVTEXT === "DEV" && (
          <div
            class="bg-danger uat-notify-strip text-center text-white
    "
          >
            Please Note: This is a UAT version of the software
          </div>
        )}

        <div className="d-flex justify-content-end flex-wrap">
          <div className="left-header d-flex position-absolute h-100 start-0 top-0">
            <div className="stroke-icon toggle-sidebar d-flex align-items-center justify-content-center">
              <a
                className="h-100 align-items-center d-flex"
                onClick={() => setIsSideBarActive((prev) => !prev)}
              >
                <IoIosArrowBack />
              </a>
            </div>
            <div className="header-search w-100 ms-5 d-none">
              <form action="/" method="get">
                <div className="position-relative"></div>
              </form>
            </div>
          </div>
          {/* <!-- /left-header --> */}
          <div className="right-header d-flex">
            <div className="switch-now d-flex align-items-center overflow-visible">
              <span className="opacity-50">Website:</span>
              <div className="toggle-website border-1 overflow-hidden d-flex align-items-center rounded-1 ms-2 overflow-visible">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline"
                    className="border-0 bg-transparent shadow-none px-0 pe-3"
                    id="dropdown-basic"
                  >
                    <span className="px-lg-4 px-2 switchweb">
                      {decodeURIComponent(website_name)}
                    </span>
                    <FontAwesomeIcon
                      className="text-black opacity-50 downarrow"
                      icon={faAngleDown}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {getAllWebsite?.data?.data?.map((item) => {
                      if (decodeURIComponent(website_name) == item?.website)
                        return;
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            handelSelect(item);
                          }}
                        >
                          {item?.website}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="switch-back">
                  <a
                    onClick={handlewebsiteredirect}
                    className="d-flex px-lg-3 px-2 text-white text-decoration-none"
                    title="Back to Home"
                  >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </a>
                </div>
              </div>
            </div>

            <div className="atmosphere-logged-user dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  className="atmosphere-account d-flex align-items-center bg-transparent border-0 p-0"
                  id="dropdown-basic"
                >
                  <div className="profile-image">
                    <span>
                      <img src={johnImage} />
                    </span>
                  </div>
                  <div className="atmosphere-username text-dark">
                    {decodeURIComponent(user_name)}
                    <span
                      className="opacity-50 d-block"
                      id="user-role"
                      style={{ fontSize: "14px" }}
                    >
                      Role: {decodeURIComponent(userRole)}
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {userRole !== "SuperAdmin" ? (
                    <Dropdown.Item
                      className="text-white fw-semibold"
                      href="javascript:void(0);"
                      onClick={handleShowModal}
                    >
                      <span className="user-icon">
                        <i className="fa fa-gear"></i>
                      </span>
                      Change Password
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}

                  <Dropdown.Item
                    className="text-white fw-semibold"
                    onClick={handleLogout}
                    href="#/action-3"
                  >
                    <span className="user-icon">
                      <i className="fa fa-right-from-bracket"></i>
                    </span>{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <ChangePasswordModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowModal={handleShowModal}
        />

        {/* <div className="dashboard-action-strip w-100 mt-4 pt-3 position-relative">
        <div className="row">
          <div className="col-6 ps-0">
            <div className="back-to-dashboard">
              <Link to=''><IoArrowBack className="h5 mb-0" /></Link>
            </div>
          </div>
          <div className="col-6">
            <div className="header-right-actions mb-1 mb-md-0 d-flex justify-content-end">
              <button className="btn btn-primary border-0"><IoShareSocial className="me-1" /> Share</button>
            </div>
          </div>
        </div>
      </div> */}
      </header>
    </>
  );
};

export default Header;
